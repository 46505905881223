<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <p id="email">{{userDetail.email}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Status</label>
          <p id="status">{{userDetail.status}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <p id="firstName">{{userDetail.firstName}}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <p id="lastName" v-if="userDetail.lastName">{{userDetail.lastName}}</p>
          <p id="lastName" v-else>NA</p>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType!=='admin' && this.userDetail.userType!=='superAdmin'"
      >
        <div class="w-full m-5">
          <label for="contactNumber">Contact Number</label>
          <p id="contactNumber" v-if="userDetail.contactNumber">{{userDetail.contactNumber}}</p>
          <p id="contactNumber" v-else>NA</p>
        </div>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="profileImage">Profile Image</label>
          <a
            style="display:block;"
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <vs-avatar size="100px" :src="userDetail.profileImage" />
          </a>
          <p v-else id="profileImage">NA</p>
        </div>
      </vs-col>-->
    </vs-row>
    <vs-row vs-justify="flex-end">
      <vs-button
        color="danger"
        style="margin-left:5px;"
        @click="$router.replace('/admin/user-management')"
      >Back</vs-button>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "UserDetails",
  data: () => ({
    userDetail: {},
  }),
  methods: {
    ...mapActions("user", ["fetchUser"]),
    getUserDetail(id) {
      let self = this;
      this.fetchUser(id).then((res) => {
        this.userDetail = res.data.data;
      });
    },
  },
  created() {
    let userId = this.$route.params.id;
    this.getUserDetail(userId);
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
</style>